.gitment-container {
  color: #787878 !important;
}

.gitment-editor-header {
  background-color: #fefefe;
}

.gitment-comment-main, .gitment-editor-main {
  background-color: #fff;
  border-radius: 3px !important;
}

.gitment-heart-icon {
  fill: #ff0808;
}
