/**
 * Common list
 */
.common-list {
  @include relative-font-size(1.0);
  min-width: 200px;

  ul {
    list-style: none;
    margin: 0;
  }

  li {
    border-bottom: solid 1px #00000018;

    &:last-child {
      border-bottom: none;
    }

    a {
      display: flex;
      justify-content: space-between;
      padding: 8px 12px;
      text-decoration: none;
      font-weight: normal;
      color: $text-color;
      transition: background 0.2s;

      &:hover {
        background-color: mix($theme-color, #eaeaea, 20%);
      }
    }

    span {
      @include relative-font-size(0.8);
      display: inline-block;
      border-radius: 10px;
      align-self: center;
      background: #000000bd;
      padding: 0px 8px;
      margin-left: 20px;
      color: $white-color;
    }
  }
}
